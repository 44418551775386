.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1080px) {
    text-align: center;

    flex-direction: column;
  }

  &__greet {
    color: $white;
    font-size: 4rem;

    @media screen and (max-width: 1080px) {
      font-size: 2.5rem;
    }

    &__name {
      color: $primary;
    }

  }

  &__date {
    color: rgba($white, .4);
    font-weight: 600;

    @media screen and (max-width: 1080px) {
      margin-top: 1rem;
    }

    &__date {
      color: $primary;
    }

  }

  &__time {
    color: $white;
    font-size: 4rem;
    font-weight: 600;

    @media screen and (max-width: 1080px) {
      margin-top: 2rem;
    }
  }

}