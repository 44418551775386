.main {
  margin-top: 2rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card:link,
.card:visited {
  color: $white;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;

  margin: 2rem;
  
  width: 300px;
  height: 180px;

  background-color: $background;
  border: 2px solid transparent;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: .2s;
  position: relative;
  overflow: hidden;

  &:hover,
  &:focus {
    color: $primary;
    border-color: currentColor;

    & > .card__name {
      bottom: 0;
    }

  }

}

.card__name {
  font-size: 1rem;

  position: absolute;
  bottom: -100%;
  left: 50%;

  transform: translate(-50%, -50%);
  transition: .2s;
}